.node-report {
  &.view-mode-full {
    .field-name-field-report-front {
      @include from($desktop) {
        width: 30%;
        float: left;
        margin-right: 1rem;
      }
    }
  }
}