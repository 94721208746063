.view-display-id-news_carousel_block {
  .view-content {
    margin: 0;
    position: relative;

    .slick-arrow {
      position: absolute;
      top: calc(50% - 20px);
      z-index: 1;
      border: none;
      background: url("../images/arrow.png") no-repeat;
      background-size: contain;
      color: transparent;
      width: 30px;
      height: 30px;
      outline: none;
      cursor: pointer;

      &.slick-prev {
        left: -20px;
        transform: rotate(90deg);
      }

      &.slick-next {
        right: -20px;
        transform: rotate(-90deg);
      }
    }

    .slick-dots {
      position: absolute;
      right: 10px;
      display: flex;
      bottom: -17px;

      li {
        button {
          background: black;
          color: transparent;
          width: 12px;
          height: 12px;
          outline: none;
          border: none;
          border-radius: 50%;
          display: block;
          margin: 0 4px;
        }
      }
    }

    .node-news {
      margin: 0 10px;

      .card-content {
        h4.title {
          min-height: 3pc;
        }
      }
    }

  }
}