.node-event.node-teaser {
  > a {
    .card-content {
      display: flex;

      .date {
        line-height: 1;
        font-size: 2.6rem;
        font-weight: bold;
        color: $dark;
        margin-right: 1.5rem;
        text-transform: uppercase;
        width: 100px;

        > * {

          text-align: right;
        }

        .month {
          font-size: 1.6rem;
        }

        .time {
          margin-top: .5rem;
          font-size: 1.1rem;
        }

        .location {
          font-size: .8rem;
          margin-top: 1rem;
          color: $info;
          line-height: 1.2;
        }
      }

      .info {
        width: 100%;

        h3 {
          font-size: 1.3rem;
          color: $primary;
        }

        .subtitle {
          font-size: 1rem;

          margin-bottom: 1rem;
        }

        .button {

        }
      }
    }
  }
}

