.print-link {
  margin-bottom: .5rem;
}

.related-content {
  h3.title {
    border-bottom: 1px solid $light;
    font-size: 1.2rem;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
  }

  .field {
    margin-bottom: 0;
    font-size: .9em;

    .field-item a {
      display: block;
      margin-bottom: .5rem;
      padding-bottom: .5rem;
    }
  }
}

.node.full {

  .table {
    font-size: .8rem !important;

    a.button {
      margin-bottom: .5rem;
    }
  }

  .published {
    margin: 1rem 0;
  }

  .meta-info {
    margin: 0;
    padding: 0;
  }

  > .content {
    margin-bottom: 3rem;
    text-align: justify;
  }

  .node-section {
    border-top: 1px solid $border;
    padding: 1rem 0 2rem 0;
    margin-top: 2rem;

    .node-section-label {
      font-weight: bold;
      margin-bottom: 1rem;

      .icon {
        margin-right: .5rem;
      }
    }

    .item {
      color: $info;
      display: flex;
      justify-content: start;

      .icon {
        margin-right: 1rem;
        margin-top: .25rem;
      }
    }
  }


  .view-filters {
    margin-bottom: 2rem;

    .container {
      width: auto;
    }
  }

  .share-container {
    max-width: 700px;
  }

  .meta-info {
    margin-bottom: 1rem !important;
  }


}


aside.sidebar-navigation {
  @include until($desktop) {
    //  display: flex;
    .sidebar-navigation-toggle {
      margin: 1rem;
      margin-left: 0;
      height: 1.8rem;
      width: 1.8rem;
      background: $light;
    }
    .inner {
      display: none;
      padding: 1rem;
      background: $white-bis;

      .is-sidebar-menu-active & {
        display: block;
      }
    }
  }
}

.node-unpublished {
  opacity: .5;
}