.view-coit-aeit-global-search {
  margin-top: 2rem !important;

  .views-exposed-form {
    margin-bottom: 3rem;

    label {
      display: none;
    }

    .field {
      margin: 0;
    }

    input[type="submit"] {
      margin-top: 0;
    }

    .widget-content {
      .views-widget {
        border: none;
        padding: 0;
        margin: 0;
      }

      overflow: auto;
      height: auto;
      max-height: none;

    }

    .views-exposed-widgets {
      display: flex;
    }
  }

  .views-row {
    background: white;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: $box-shadow;

    .views-field-type {
      color: $grey;
      font-size: $size-7;
    }

    .views-field-title-field {
      h3 a {
        font-weight: bold;
      }
    }

    .views-field-search-api-excerpt {
      font-size: $size-7;
    }
  }
}

.global-search {
  display: flex;

  > .field {
    width: 100%;
    margin-right: 1rem;
  }
}