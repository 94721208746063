
.frontpage-task {
  height: 0;
  padding-bottom: 2/3*100%;
  display: block;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  .task-content {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all .4s;

    &:hover {
      background: rgba($info, .9);

      h3, .subtitle {
        color: $primary !important;
      }
    }

    h3, .subtitle {
      color: white;
      text-align: center;
      transition: all .4s;
      margin: 0 1rem;
    }

    h3 {
      margin: 0 1rem;

      font-size: 1.5vw !important;
      @include until($desktop) {
        font-size: 3vw !important;
      }
      @include until($tablet) {
        font-size: 4vw !important;
      }
    }
  }
}