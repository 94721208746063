.view-coit-aeit-awards {
  .view-content {
    > table {
      thead {
        th {
        color: #F29D00;
        }
      }
    }
  }
}