.node-media-album {
  .field-name-media-album-photos {
    .field-items {
      @include clearfix;
      margin-left: -.5rem;
      margin-right: -.5rem;

      > * {
        margin: .5rem;
        float: left;
        width: calc(20% - 1rem);
        @include until($tablet) {
          width: calc(50% - 1rem);
        }

        @include until($tablet) {
          &:first-child {
            width: calc(100% - 1rem);

            img {
              width: 100%;
            }
          }
        }

        @include from($desktop) {
          &:first-child {
            width: calc(60% - 1rem);

            img {
              width: 100%;
              height: 303px;
            }
          }
        }
        @include from($widescreen) {
          &:first-child {
            img {
              height: 361px;
            }
          }
        }

        @include from($fullhd) {
          &:first-child {
            img {
              height: 419px;
            }
          }
        }
      }
    }
  }
}

.node-media-album.node-teaser {
  &:hover {
    background: darken($white-ter, 5);
  }

  > a {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;


    .card-content {
      align-content: stretch;
      flex: 1;
      display: flex;
      flex-direction: column;

      > footer {
        display: flex;
        justify-content: space-between;
        margin-top: auto;

        > .tags {
          margin-bottom: 0;

          > span {
            margin: 0;
          }
        }
      }

    }

  }

}