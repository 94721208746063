@charset "utf-8";
@import "../node_modules/bulma/sass/utilities/initial-variables";
@import url('https://fonts.googleapis.com/css?family=Darker+Grotesque:400,700&display=swap');

$mail-width: 720px;
@import "variables/_colors.scss";
@import "variables/_fonts.scss";
@import "variables/_sizes.scss";

@import "../node_modules/bulma/sass/utilities/_all";
@import "../node_modules/bulma/sass/base/_all";
@import "../node_modules/bulma/sass/elements/_all";
@import "../node_modules/bulma/sass/components/_all";
@import "../node_modules/bulma/sass/grid/_all";
@import "../node_modules/bulma/sass/layout/_all";
@import "../node_modules/bulma/sass/form/all";
@import "../node_modules/bulma-checkradio/src/sass/index";

@import "../node_modules/bulma-divider/src/sass/index";


@import "base/_card.scss";
@import "base/_colorbox.scss";
@import "base/_content.scss";
@import "base/_field.scss";
@import "base/_flag.scss";
@import "base/_forms.scss";
@import "base/_modal.scss";
@import "base/_throbber.scss";
@import "components/_news-carousel.scss";
@import "components/_news-categ-nav.scss";
@import "components/beans/_featured_block.scss";
@import "components/beans/_slideshow.scss";
@import "components/blocks/_block-bean-front-tasks-2-2.scss";
@import "components/blocks/_block-bean-front-tasks.scss";
@import "components/blocks/_blocks.scss";
@import "components/blocks/_eu_cookie_compliance.scss";
@import "components/eck_entities/_advert.scss";
@import "components/eck_entities/_slideshow.scss";
@import "components/eck_entities/inline/_accordion.scss";
@import "components/eck_entities/inline/_grid.scss";
@import "components/forms/_checkout.scss";
@import "components/forms/_course_information.scss";
@import "components/forms/_entityform.scss";
@import "components/forms/_membership.scss";
@import "components/forms/_newsletter.scss";
@import "components/forms/_user.scss";
@import "components/forms/_vbo.scss";
@import "components/forms/_webform.scss";
@import "components/navigation/_breadcrumb.scss";
@import "components/navigation/_menu.scss";
@import "components/navigation/_navbar.scss";
@import "components/navigation/_og_group.scss";
@import "components/navigation/_sidebar_menu.scss";
@import "components/navigation/_social_media_menu.scss";
@import "components/navigation/_tabs.scss";
@import "components/nodes/_album.scss";
@import "components/nodes/_blog.scss";
@import "components/nodes/_comments.scss";
@import "components/nodes/_company.scss";
@import "components/nodes/_event.scss";
@import "components/nodes/_general.scss";
@import "components/nodes/_job_offer.scss";
@import "components/nodes/_journal.scss";
@import "components/nodes/_news.scss";
@import "components/nodes/_report.scss";
@import "components/nodes/_resource.scss";
@import "components/profiles/_membership.scss";
@import "components/views/_archivo_bit.scss";
@import "components/views/_blog.scss";
@import "components/views/_events.scss";
@import "components/views/_exposed_widget.scss";
@import "components/views/_news.scss";
@import "components/views/_search.scss";
@import "components/views/_view-coit-aeit-awards.scss";
@import "components/views/_view-coit-professional-profiles.scss";
@import "components/views/_views.scss";
@import "layout/_frontpage.scss";
@import "layout/_newsletter.scss";
@import "layout/_page.scss";



.see-more-news {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}
