#user-profile-form {
  .password-strength {
    display: none;
  }

  .is-password .field-body {
    //    max-width: 30em;
  }

  .description {
    margin-top: .5rem;
    font-size: $size-7;
  }

  .field-name-og-user-node {
    .field.is-select {
      display: block;

      text-align: left;
    }

    label,
    .label {
      font-weight: bold;
      text-align: left;
      margin-bottom: 1rem;
    }
  }

  table {
    width: 100%;

    input {
      max-width: 15em;
    }
  }

  .user-picture + .field {
    display: block;
    flex: none;
    text-align: left;

    .field-label {
      text-align: left;
      margin-bottom: .5rem;
    }
  }
}

.confirm-parent,
.password-parent {
  width: auto;
}

.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;

  .field-label-inline {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .field-label {
    margin-right: 0;
    text-align: left;
  }
}

// Added special behavior to parents item in view experts.
#views-exposed-form-coit-professional-profiles-page-3 {

  [class*='-4639'],
  [class*='-4640'],
  [class*='-4641'],
  [class*='-4642'],
  [class*='-4643'],
  [class*='-4644'] {

    input {
      display: none;
    }

    .is-checkradio[type="checkbox"].is-small + label {
      font-size: 22px;
      font-weight: bold;
      padding: 0 !important;
      margin: 0;
    }

    label {

      &:before, &:after {
        content: none;
      }

    }
  }

}

// Added special behavior to parents item in form experts.
.field-name-membership-expert-expertise {

  select {
    cursor: unset;

    option {
      cursor: pointer;
      padding: 0.5em 1.5em;

      &[value="4639"],
      &[value="4640"],
      &[value="4641"],
      &[value="4642"],
      &[value="4643"],
      &[value="4643"] {
        color: white;
        font-weight: bold;
        background: $primary;
        pointer-events: none;
        cursor: default;
        padding: 0.5em 1em;
      }

    }
  }

}
