@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ajax-progress-throbber {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(white, .6);
  left: 0;
  top: 0;

  .throbber {
    position: absolute;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
    position: absolute !important;
    background: none;
    line-height: 0;

    &:after {
      -webkit-animation: spin .5s infinite linear;
      animation: spinAround .5s infinite linear;
      border: 2px solid $primary;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      position: relative;
      width: 1em;
    }
  }
}