
.title-carousel-slide {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

.subtitle-carousel-slide {
  color: white;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}
