section.header {
  background: white;
  z-index: 3;
  position: relative;
}

.hero.pre {
}

.hero.menu {
  background: $primary;
  box-shadow: rgba(black, .3) 0 0 5px;
  z-index: 2;
  position: relative;

  .mobile-menu {
    margin-bottom: 0;
    padding: $column-gap/2 $column-gap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include from($desktop) {
      display: none;
    }
  }

  @include until($desktop) {
    .container.navigation {
      display: none;
    }
    .is-mobile-menu-active & {
      .container.navigation {
        width: 100%;

        .navbar {
          width: 100%;
        }

        display: block;

        .navbar-menu {
          display: block;
        }
      }
    }
    .is-menu-fixed & {
      .container.navigation {
        @include from($tablet) {
          margin-top: 64px;
        }
      }
    }
  }
}

.breadcrumbs,
section.pre,
section.main,
section.post,
footer {
  @include until($desktop) {
    .is-mobile-menu-active & {
      display: none;
    }
  }
}

section.navigation {
  @include from($desktop) {
    .is-menu-fixed & {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 4;
    }
    .is-menu-fixed.navbar-fixed & {
      top: 39px;
    }
    .is-menu-fixed.navbar-tray-open.navbar-horizontal & {
      top: 80px;
    }
  }

  @include until($desktop) {
    .navbar {
      min-height: 0;
    }
    .mobile-menu {
      .is-mobile-menu-active & {
        border-bottom: 2px solid lighten($primary, 4);
      }

      .is-menu-fixed & {
        position: fixed;

        top: 0;
        width: 100%;
      }

      z-index: 9999;
      background: $primary;

      .navbar-fixed & {
        top: 39px;
      }

      .navbar-burger {
        color: $white;
      }
    }
  }
}

section.header > .container {
  @include until($desktop) {
    padding: 0;
    > * {
      display: none !important;
    }
    #block-search-form {
      width: 100%;
      margin: 2rem;

      .search-is-active & {
        display: block !important;
      }
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;

  #logo {
    display: flex;
    align-items: center;

    img {
      margin-right: 1rem;
      width: 214px;
    }

    .page-title {
      white-space: nowrap;
      font-size: .8rem;
      line-height: 1.3;
    }
  }

  #block-menu-menu-social-media {
    margin-left: auto;
    margin-bottom: 0;
    margin-right: 1rem;
  }

  #block-search-form {
    font-size: .8em;

    .field {
      margin-bottom: 0;
    }

    input[type="text"] {
      min-width: 250px;
      @include until($tablet) {
        min-width: auto;
        &::placeholder {
          font-size: .8rem;
        }
      }
    }
  }
}

section {
  @include until($desktop) {
    overflow: hidden;
  }
}

section.pre {
  background: $light;
  @include from($desktop) {
    .is-menu-fixed & {
      margin-top: 120px;
    }
    .is-menu-fixed.front & {
      margin-top: 53px;
    }
  }
}

.container.navigation {
  @include until($desktop) {
    #logo-menu {
      display: none;
    }
  }
}

section.main {
  border-bottom: 1px solid $white-ter;
}

section.main > .container {
  :not(.front &) {
    margin-top: 3rem;
  }

  @include until($desktop) {
    padding: 1rem;
  }

  .tabs {
    margin-top: 2rem;
  }

  .node.node--full {
    margin: 1rem 0;
  }

  @include from($desktop) {
    .node.node--full {
      margin: 3rem 0;
    }
  }

}

#block-system-main > .view {
  margin: 5rem 0;

  .node & {
    margin: 0;
  }
}

#logo-mobile {
  display: flex;
  justify-content: center;
  align-self: end;
  align-items: center;
  color: $white;
  height: 50px;

  img {
    height: 2.4em;
    width: auto;
  }

  .page-title {
    @include until($tablet) {
      font-size: 2.5vw;
    }
    white-space: nowrap;
    align-self: center;
    text-align: left;
    line-height: 1;
    margin-left: 1rem;
    width: 0;
  }
}


footer.footer {
  background: $primary;
  color: white;
  padding: 3rem 1.5rem 4rem 1.5rem;

  a {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }

  ul.menu {
    list-style: none;

    li {
      margin: 0;
      list-style: none;
    }
  }
}


.hero.breadcrumbs {
  .search-is-active & {
    display: none;
  }
}

.search-toggle {
  cursor: pointer;
  display: block;
  position: relative;
  color: white;
  font-size: .9em;
  @include from($tablet) {
    margin-right: 1rem;
  }

  &:hover {
    color: white;
  }
}

.container.masquerade {
  padding: 1rem 0;

  > * {
    max-width: 200px;
    font-size: .9em;
  }
}

.hero-body {
  @include until($tablet) {
    padding: 1rem .5rem;
  }
}