.node-job-offer {
  .group-main,
  .group-application {
    margin-top: 3rem;

    > .field {
      > .field-label {
        @extend .coit-aeit-box;
      }
    }
  }
}