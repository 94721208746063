form.webform-client-form {
  padding: 2rem;
  background: $white-bis;
  border-radius: $radius;
  margin-bottom: 2rem;

  .webform-component {
    .field-body {
      flex-wrap: wrap;

      .description {
        width: 100%;
        margin-top: .5rem;
      }
    }

    &.webform-component-grid {
      overflow: auto;
    }
  }
}

input.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Webform Grid Field */
table.webform-grid{
    & .checkbox.webform-grid-option{
        display: revert;
        text-align: center;
        height: 50px;
    }

    & td.webform-grid-question {
        vertical-align: middle;
    }
}

.radio-label::before, .radio-label::after {
  margin-left: 10px;
}
