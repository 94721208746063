.node-company.node-teaser {
  figure {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    height: calc(100px + 2rem);

    img {
      max-height: 80px;
      height: auto;
      width: auto;
      margin: 0 auto;
    }
  }

  h4 {
    margin: 0;
  }
}