.vbo-views-form {
  .container-inline {
    display: flex;
    flex-direction: column;

    .field-label {
      display: block;
      text-align: left;
      margin-bottom: 1rem;

      h3 {
        font-size: 1.1rem;
      }
    }

    .field-body {
      .field {
        display: flex;

        .is-select {
          margin-right: 1rem;
        }
      }
    }
  }

  .is-checkradio {
    opacity: 1;
  }
}

form.confirmation {
  ul {
    list-style: circle;
  }

  .form-actions {
    .field {
      display: flex;
      align-items: center;

    }

    .field-label {
      display: none;
    }
  }
}