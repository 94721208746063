// Override .list
.view.list {
  background: none;
  border-radius: 0;
  box-shadow: none;
}

.view > .view-content {
  margin-bottom: 3rem;
}

.view .columns > .column > * {
  height: 100%;
}

.view table {
  img {
    min-width: 100px;
  }
}

.view-header {
  margin-bottom: 2rem;
}