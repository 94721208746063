#block-bean-front-tasks {

  .frontpage-task {

    .task-content {
      background: rgba($info, .9);

      &:hover {
        background: rgba($info, .5);

        h3, .subtitle {
          color: $primary !important;
        }

      }
    }
  }

}
