.menu .button {
  font-size: .8rem;
  @include from($fullhd) {
    font-size: .9rem;
  }
}

.menu {
  font-size: .9rem;
  @include from($fullhd) {
    font-size: 1rem;
  }
}

.menu-divider {
  @include from($widescreen) {
    border-left: 1px solid rgba(white, .1);
    margin-left: auto;
  }

}

.container.navigation {

  .navbar {

    @include until-widescreen() {
      #logo-menu {
        display: none !important;
      }
      .navbar-end,
      .navbar-start,
      .button {
        font-size: $size-7;
      }
      .button:not(:last-child) {
        margin-right: 0 !important;
      }
    }

    .navbar-end {
      margin-left: 1rem;
    }

    .navbar-start,
    .navbar-end {

      .button {
        @include until($desktop) {
          margin: .5rem;
          height: auto !important;
        }
      }

      a {
        font-weight: bold;

        &.menu-node-unpublished {
          opacity: .3;
        }
      }

      .navbar-menu {
        @include until($desktop) {
          padding: 0;
        }
      }
    }

    .navbar-end {
      .navbar-menu {
        align-items: center;
        margin-right: 0 !important;
      }

      @include until($desktop) {
        padding: 1rem;
        margin: 0;
        background: white;
      }
    }

    .navbar-item {
      &.is-active {
        background-color: darken($primary, 4);
        color: white;
      }

      .navbar-link {
        color: white;
        background: $primary;

        &.is-active {
          background-color: darken($primary, 4);
          color: white;
        }

        &:after {
          border-color: white;
        }

      }

      .navbar-dropdown {
        border-color: darken($primary, 5);
        background: $primary;

        a:not(.is-active) {
          color: white;

          &:hover {
            color: $primary;
          }
        }

        a.navbar-item:hover {
          background: $info;
          color: white;
        }

        .navbar-divider {
          background-color: lighten($primary, 2);
        }
      }

      .navbar-link:hover,
      &:hover .navbar-link {
        @include from($desktop) {
          background-color: darken($primary, 6);
        }
      }
    }


    .navbar-end {
      align-items: center;
    }

    #logo-menu {
      align-self: center;
      width: 0;
      transition: width .3s ease;

      img {
        align-self: center;
        height: auto;
        width: 1.4em;
        max-width: none !important;
      }

      .is-menu-fixed & {
        width: 1rem;
        display: flex;
        // margin-left: $column-gap;
        margin-right: $column-gap;
      }
    }

    .mobile-menu {
      #logo-mobile {
        img {
          width: 25px;
        }
      }
    }
  }

  @include until($tablet) {
    z-index: 9999;
    top: 72px;
    height: 100%;
    padding-bottom: 70px;
    position: fixed;
    overflow: auto;
    .navbar {
      .navbar-menu {
        background: none;
        box-shadow: none;
      }

      .navbar-item.button {
        margin: 1rem !important;
      }
    }
  }
}