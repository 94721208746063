.menu .menu-list {
  li {
    a {
      &.menu-node-unpublished {
        opacity: .3;
      }

      margin-bottom: .5rem;
      display: flex;

      span:first-child {
        &.icon {
          width: 1rem;
          margin-top: .15rem;
          font-size: .6rem;
          margin-right: .5rem;
        }
      }

      span:not(.icon) {
        width: calc(100% - 2rem);
      }
    }
  }

  .section-link a {
    flex-direction: column;
  }
}