.content {
  @include clearfix;

  iframe:not(.coit-aeit-custom-size) {
    height: 400px;
  }

  a {
    border-bottom: 1px solid $link;
    color: $link;
    font-weight: bold;

    &:hover {
      background: $link;
      color: white;
    }
  }

  .subtitle {
    color: $grey-dark;
  }

  .coit-aeit-box-left,
  .coit-aeit-box-right,
  .coit-aeit-box {
    background: $light;
    display: block;
    padding: $box-padding;
    font-size: 1.1em;
  }

  @include from($tablet) {

    .coit-aeit-box-left {
      float: left;
      width: 30%;
      margin-right: 1rem;
    }
    .coit-aeit-box-right {
      float: right;
      width: 30%;
      margin-left: 1rem;
    }
  }

  img {
    width: 100%;
  }

  table {
    img {
      //   width: auto !important;
      //  height: auto !important;
      max-width: 10rem;
    }
  }

  table tr td p {
    text-align: justify;
  }
}

.content.cke_editable {
  padding: 1rem;
}

strong {
  color: $primary;
}