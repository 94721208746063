#modalBackdrop {
  background: rgba(black, .8) !important;
}

#modalContent {
  .ctools-modal-content {
    border: none;
    box-shadow: rgba(black, .3) 0 0 .5rem;
    margin: 0;
    border-radius: $radius;
    background: none;

    .modal-header {
      background: $primary;
      border-radius: $radius $radius 0 0;
      padding: 2rem;
      color: white;
      font-size: 1.3rem;
      font-weight: bold;
      position: relative;

      .popups-close {
        position: absolute;
        right: 2rem;
      }
    }

    .modal-scroll {
      background: white;
      border-radius: 0 0 $radius $radius;
    }
  }

}

