@mixin newsletter-styles {
  table {
    font-size: 1em;
    width: 100%;
    border: none;

    tr {
      border: none;
      background: none;

      &:first-child {
        border: none;
      }

      &:hover {
        background: inherit !important;

        td {
          background: inherit !important;
        }
      }

      td {
        border: none;
        border-bottom: 3px solid #eee;
        font-size: 1em;
        padding: 5px;

        &.coit-aeit-highlighted-cell {
          background: $primary !important;
          color: white;
          font-weight: bold;
          font-size: 1.1em;
          border-bottom: 3px solid $primary;
        }

        img {
          width: 100%;
          height: auto;
        }

        &:hover {
          background: none;
        }
      }

      &.count-2 {
        .column-0 {
          width: 30%;
          border-top: none;
        }
      }
    }
  }
  p.coit-aeit-highlight {
    background: $primary;
    color: white;
    padding: 1.4em;
    font-style: italic;
    font-size: 1.2em;
  }
}


.newsletter {
  font-family: Arial, Helvetica, sans-serif;
  font-size: .9em;
  background: #EEE;
  max-width: 100% !important;

  > .header {
    margin: 0 auto;
    max-width: 680px;
    color: #777;
    font-size: .8em;
    padding: 20px;

    .open-in-website {

    }

    .social-icons {
      float: right;

      a {
        img {
        }
      }
    }

  }

  > .content {
    .texto-sobre-imagen {
      position: relative;
      max-width: 680px;
      top: -50px;
      left: 200px;
    }

    table {
      width: 480px;

      .cabecera-decana {
        position: relative;
        display: inline-block;
        text-align: justify;

        .texto-encima {
          position: absolute;
          top: -10px;
          left: 50px;
        }

        .centrado {
          position: absolute;
          top: 75%;
          left: 17%;
          transform: translate (-17%, -75%);
        }
      }

      td {
        position: relative;
      }
    }

    max-width: 680px;
    padding: 20px;
    margin: 0 auto;
    background: white;

    table.header {
      border-bottom: 2px solid $white-bis;
      margin-bottom: 1em;

      .logo {
        width: 30%;

        img {
          width: 200px;
        }
      }

      .title {
        width: 70%;
        font-size: 1em;
        text-align: right;
        font-weight: bold;
      }

      &.naranja,
      &.azul {
        border: none;
        border-collapse: collapse;
        border-spacing: 0;

        td {
          border: none;
          padding: 0;
        }

        .logo {
          padding-bottom: 5px;
          padding-left: 5px;
          padding-top: 5px;
        }

        .title {
          padding-right: 5px;
          color: white;
        }
      }

      &.naranja {
        td {
          background-color: $primary;
        }
      }

      &.azul {
        td {
          background-color: $primary;
        }
      }
    }

    .field--name-newsletter-header {
      margin-bottom: 1em;
    }

    .field--name-newsletter-body {
      @include newsletter-styles;
    }
  }

  > .footer {
    margin: 0 auto;
    max-width: $mail-width;
    color: #777;
    font-size: .8em;
    padding-bottom: 2em;
  }
}

.node--simplenews {
  @include newsletter-styles;

  .field--name-newsletter-header {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &.node--full {
    padding: 10px;
    border: 1px solid $white-bis;
    background: white;
    max-width: $mail-width !important;
    margin: 0 auto;
  }
}

.cke_editable {
  @include newsletter-styles;
}
