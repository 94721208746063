.node.node-resource.node-teaser {
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;

  .subtitle {
    font-size: 1em;
  }

  .buttons {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 0;

    > * {
      margin-bottom: 0;
    }

    .button {
      &:hover {
        @extend .is-info
      }
    }
  }

}

.node.node-resource.full {
  .buy-conditions {
    margin-bottom: 1rem;
  }
}