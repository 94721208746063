$field-label-body-ratio: 3;
.field-body {
  .field .field {
    margin-bottom: 0;
  }

  @include tablet {
    display: flex;
    flex-basis: 0;
    flex-grow: $field-label-body-ratio;
    flex-shrink: 1;
    .field {
      margin-bottom: 0;
    }
    & > .field {
      flex-shrink: 1;

      &:not(.is-narrow) {
        flex-grow: 1;
      }

      &:not(:last-child) {
        margin-right: 0.75rem;
      }
    }
  }
}

@mixin horizontal_pseudo() {
  @include from($tablet) {
    display: flex;
    &:before {
      flex-grow: 1;
      content: ' ';
      margin-right: 1.5rem;
    }
    > * {
      flex-grow: $field-label-body-ratio;
      flex-basis: 0;
      flex-shrink: 1;

      > p {
        margin: .5rem 0;
      }
    }
  }
}

.cke_contents {
  min-height: 10rem;
}

form {
  position: relative;
}

.form-item {
  margin: 0;
}

.field {
  @include from($tablet) {
    margin-bottom: 1rem;
  }
  @include until($tablet) {
    .select {
      width: 100%;
    }
  }
}

.field-group-fieldset {
  background: $white-ter;
  padding: 1rem;
  border-radius: $radius;

  > h3 {
    @include horizontal_pseudo;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0;
  }

  > .description {
    margin-left: calc(100% / #{$field-label-body-ratio} - 1.5rem);
  }

  margin-bottom: 3em;

  .field-group-fieldset {
    padding: 0;
  }
}

.container-inline-date .date-padding {
  float: none;
}

.filter-guidelines,
.filter-help {
  display: none;
}

.filter-wrapper {
  padding: 0;

  a {
    padding: 0;
  }
}

.date-no-float {
  width: 100%;
}

.field.is-horizontal {

  .media-widget {
    display: flex;

    .preview {
      margin-right: 0;

      > * {
        margin-right: .5rem;
      }
    }
  }

  select {
    width: 100%;
  }

  .date-no-float .field.is-horizontal,
  .link-field-url .field.is-horizontal {
    display: block;

    .field-label {
      text-align: left;
      margin: 0 0 .5rem 0;
    }
  }

  .field-label {
    min-width: calc(100% / 6);
    padding-top: .5rem;
    font-weight: normal;
    font-size: .8rem;
    @include until($tablet) {
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-bottom: .5rem;
      border-bottom: 2px solid $border;
    }
  }

  .field.is-horizontal .field-label {
    @include until($tablet) {
      margin-top: 0rem;
      margin-bottom: 0rem;
      padding-bottom: 0rem;
      border-bottom: none;
    }
  }


  &.is-checkboxes {
    .field-body {
      display: block;
    }
  }

  .is-checkradio + label:before {
    background: white;
  }

  &.is-checkbox {
    .field > label {
      @include from($tablet) {
        &:after,
        &:before {
          margin-left: -.4rem;
          margin-top: .2rem;
        }
      }
    }
  }
}

.fieldset {
  margin: 1rem 0;

  .field-label h3 {
    font-weight: bold;
  }

  > h3 {
    font-size: 1rem;
    margin-bottom: 2rem;
    font-weight: bold;
  }
}

.field-type-file {
  margin: 1rem 0;
}

.field-type-markup {
  font-size: .8em;
  @include from($tablet) {
    display: flex;
    margin-bottom: 2rem;
    margin-top: 1rem;
    @include horizontal_pseudo();

  }
}

.field-widget-options-onoff {
  @include horizontal_pseudo;
}

.element-hidden {
  display: none;
}


input.is-checkradio + label {
  padding-left: 2rem !important;
  display: block !important;
  margin-left: 0 !important;

  &:after,
  &:before {
    position: absolute;
    left: 0 !important;
    top: .3rem !important;
    background: white;
  }

  &:after {
    top: .6rem !important;
    left: .6rem !important;

    .form-type-radio & {
      top: .3rem !important;
      left: 0rem !important;
    }
  }
}

.is-checkradio[type="checkbox"].is-small + label {
  &:after {
    left: .45rem !important;
  }
}

.form-managed-file {
  display: flex;

  .file {
    margin-right: .5rem;
  }
}

input[readonly] {
  opacity: .5;
}

select, input {
  &.error {
    border: 2px solid red;
  }
}

.form-actions .field-body .field > input {
  margin-right: 1rem;
}

.select select optgroup {
  padding-top: .5em;

  > option:first-child {
    margin-top: .5em;
  }
}

table tr.draggable td a.tabledrag-handle {
  .handle {
    background-position: 6px 0px;
    height: 17px;
    margin: .8em .8em .8em 1em;
    padding: 0.42em 0.5em;
  }

  &.tabledrag-handle-hover .handle {
    background-position: 6px -20px;
  }
}

table tr.draggable td input {
  margin: .5rem;
  width: auto;

  &.form-autocomplete {
    background-position: 98% center;
  }
}

.container-inline div.select {
  display: inline-block;
}