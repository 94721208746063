.block {
  &#block-delta-blocks-action-links {
    margin-bottom: 1.5rem;

    .dropdown {
      width: 100%;
    }

    .dropdown-trigger {

      width: 100%;

      button {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.hero-body.tasks {
  .box {
    box-shadow: none;
    background: $light;

    &:hover {
      background: $info;

      .icon,
      .title,
      .subtitle {
        color: $white !important;
      }
    }
  }
}
