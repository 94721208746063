// profiles
.entity-profile2.profile2-preinscription.view-mode-preinscription_pdf,
.entity-profile2.profile2-membership.view-mode-delegation_pdf,
.entity-profile2.profile2-membership.view-mode-aeit_pdf,
.entity-profile2.profile2-membership.view-mode-coit_pdf,
.entity-profile2.profile2-preinscription.view-mode-preinscription_aeit_pdf {
  p, ul, ol {
    margin-bottom: .5rem;
  }

  page-break-after: always;
  font-size: .9em;
  position: relative;
  text-align: justify;

  h1 {
    margin-top: 1em;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 1em;
  }

  h3 {
    font-size: 1.2em;
    margin: 0;
  }

  .logo {
    max-width: 180px;
    height: auto;
    margin-bottom: 2em;
    clear: right;
    margin-top: -20px;
    max-height: 40px;
  }

  .photo {
    position: absolute;
    top: -20px;
    right: 0;
    text-align: center;
    width: 80px;
    padding-top: 50px;
    padding-bottom: 50px;
    border: 1px solid #CCC;
  }

  .membership-number {
    font-size: .9em;
    display: block;
  }

  .field-group-fieldset {
    margin-top: 10px;
    margin-bottom: 20px;

    h3 {
      margin-top: 0;
      color: $primary;

      &:before {
        display: none;
      }
    }
  }

  .field.field-label-inline,
  .field.field-label-above {
    margin: .5rem 0;
    padding: 0;

    .field-label {
      font-weight: bold;
      display: inline-block;
      width: 20%;
      border-bottom: none;
    }

    .field-items {
      width: 70%;
      margin-left: 5%;
      display: inline-block;

      .field-item {
        border-bottom: 1px dotted #CCC;
      }
    }

    &.field--type-ds {
      .field-items {
        width: 100%;
        margin-left: 0;

        .field-item {
          border-bottom: none;
        }
      }
    }
  }

  .field-name-conditions-coit,
  .field-name-condiciones-aeit,
  .field-name-condiciones-territorial {
    page-break-before: always;
    font-size: .85em;

  }
}

.entity-profile2.profile2-preinscription.view-mode-preinscription_pdf,
.entity-profile2.profile2-preinscription.view-mode-preinscription_aeit_pdf {
  .field--name-conditions-preinscription {
    margin-top: 30px;
  }
}

.field-name-conditions-coit.field-type-ds {
  img {
    display: none;
  }
}


form[id^="profile2"] {
  text-align: justify;

  .field-group-fieldset {
    padding-right: 2rem;
  }
}