.organic-group {
  margin-bottom: 2rem;
  padding: .5rem;
  background: $white-ter;
  border-radius: $radius-large;

  ul.menu-list {
    li.no-access {
      opacity: .5;
    }
  }

  span {
    color: $primary;
  }

  span.icon {
    color: $primary;
  }

  a {
    margin-bottom: 0 !important;
  }

  a.is-active {
    color: $body-color;
    background: none;
    font-weight: bold;
  }

  ul ul li {
    a:hover {
      background-color: $white-bis;
    }
  }

  ul.menu-list {
    border-color: $link;
  }
}