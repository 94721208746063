.node.node-news.node-teaser {
  display: flex;
  flex-direction: column;

  > a {
    display: contents;
  }

  .card-image {
    display: flex;
    width: 100%;

    > * {
      width: 100%;
    }
  }

  .card-content {
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: flex-start;

    .content {
      display: contents;

      .read-more {
        margin-top: auto;
      }
    }
  }

  .front & {
    border: none;
    box-shadow: none;

    .card-content {
      padding: 0;

      .read-more {
        font-weight: bold;
        line-height: normal;
        vertical-align: center;
        font-size: .8em;
        align-items: center;
      }
    }

    a:hover {
      h4 {
        text-decoration: none;
      }

      .read-more {
        text-decoration: underline;
      }
    }
  }

  a:hover h4 {
    text-decoration: underline;
  }

  .date {
    margin: .5rem 0 .5rem -.25rem;
    display: flex;
    align-items: center;
  }
}

.node-news.full {
  strong,
  .subtitle,
  h1 {
    color: $primary;
  }

  @include from($widescreen) {
    margin: 0 auto;
    max-width: $tablet;
  }
}

.columns.news-frontpage {
  > .column {
    display: flex;

    > * {
      padding: $column-gap;
      background: $white-ter;
      border-radius: $radius;

      &:hover {
        background: darken($white-ter, 5);
      }
    }
  }
}