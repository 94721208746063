
#comments {
  max-width: $tablet;

  margin: 2rem auto;

  h2 {
    font-size: 1.2rem;
  }
}

.comment {
  border-bottom: 1px solid $light;
  margin-bottom: 1rem !important;
  padding-bottom: 1rem !important;

  .comment-body {
    background: $white-ter;
    border-radius: $radius;
    padding: .6rem 1rem;
    font-weight: bold;
  }

  .submitted {
    margin-top: .5rem;
    font-size: .8rem;
  }

  .comment-content {
    display: flex;
    flex-direction: column;

    .buttons {
      margin-top: 1rem;

      ul li {
        padding: 0;
        margin: 0;

        a {
          font-size: .8rem;
          margin-right: .3rem;
        }
      }
    }
  }
}