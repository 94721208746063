a.flag-link-toggle {
  display: block;
  max-width: 100%;
  white-space: normal;
  padding: 1rem 1rem 1rem 3.5rem;
  height: auto;
  text-align: left;
  font-size: .9em;
  position: relative;
  display: flex;
  line-height: 1;

  span.icon {
    position: absolute;
    left: 1.5rem;
  }
}