.accordion {
  background: $white-ter;
  border-radius: $radius;
  margin: 2rem 0;
  width: 100%;

  .accordion-item {
    border-bottom: 1px solid $border;

    &:last-child {
      border-bottom: none;
    }

    .title {
      padding: 1rem;
      margin-bottom: 0;
      line-height: 1;
      cursor: pointer;
      align-items: center;
      display: flex;
      justify-content: space-between;

      .icon {
        margin-right: .5rem;
      }

      .icon.close {
        display: none;
      }

      &:hover {
        background: darken($white-ter, 4);
      }

    }

    .content {
      display: none;
      padding: 1rem;
      background: $white-bis;
    }

    &.is-active {
      .title .icon.close {
        display: block;
      }

      .title .icon.open {
        display: none;
      }

      .content {
        display: block;
      }
    }
  }
}
