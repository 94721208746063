.field-name-membership-form-information {
  &:before {
    display: none;
  }
}


#membership-delegation {
  h3 {
    display: none;
  }
}