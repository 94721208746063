.front {
  .news-frontpage {
    .card {
      width: 100%;
    }
  }

  .main-column {
    display: flex;
    flex-direction: column;

    .front-tasks {
      margin-top: auto;
    }
  }

  #block-menu-menu-social-media {
    .navbar-menu {
      display: flex;
      justify-content: space-evenly;

      a {
        font-size: 1.5rem;
      }
    }
  }

  #block-bean-front-partners {
    text-align: center;
    margin-top: 2rem;

    h4 {
      border-bottom: 2px solid $white-ter;
      font-size: 1.5rem;
      color: $grey-light;
      padding-bottom: 1rem;
    }

    width: 100%;

    .owl-nav,
    .owl-dots {
      display: none;
    }

    .front-partners-slider {
      width: 100%;
      overflow: hidden;

    }

    .owl-item {
      min-height: 1px;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;

      .item {
        margin: 2rem;
      }
    }

    a.frontpage-partner {
      width: 120px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: .7;

      &:hover {
        opacity: 1;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }


  #block-coit-aeit-advertising-advert-sidebar-right {
    .entity-advert {
      img {
        float: left;
      }

      margin-bottom: $column-gap * 2;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  #block-coit-2019-front-verification-block {
    margin-bottom: 2rem;

    .block-content a {
      font-weight: bold;
      height: auto;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  #block-coit-aeit-general-twitter {

    box-shadow: $box-shadow;

    .twitter-header {
      //   background:$primary;
      //color:white;
      padding: .5rem;
      font-size: $size-7;
      font-weight: bold;
    }

    iframe {
      max-width: calc(100% - 5px) !important;
    }

    .twitter-footer {
      padding: .5rem;

      a {
        font-size: .6em;
      }
    }
  }

}