#block-views-coit-aeit-blog-most-read,
#block-views-coit-aeit-blog-most-recent {
  h2 {
    font-weight: bold;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
    border-bottom: 1px solid $light;
  }

  ul,
  ul li {
    margin: 0;
    padding: 0;

    a {
      margin-bottom: .5rem;
      display: block;
      font-size: $size-7;
    }
  }
}