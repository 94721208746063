.breadcrumb {
  margin: .5rem 0;
  font-size: 1.2rem;
  padding: $column-gap;
  transition: all .3s ease;
  @include until($desktop) {
    font-size: .8rem;
  }
  @include from($desktop) {
    .is-menu-fixed & {
      padding: .5rem $column-gap;
      margin: .3rem 0;
      font-size: .9rem;
    }
  }
}