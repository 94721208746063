#block-bean-front-tasks-2-2 {

  .frontpage-task {

    .task-content {
      background: rgba($primary, .9);

      &:hover {
        background: rgba($primary, .5);

        h3, .subtitle {
          color:$info !important;
        }

      }
    }
  }

}
