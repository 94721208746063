.view-display-id-cat_nav_block {
  .view-content {
    display: flex;
    flex-wrap: wrap;

    .views-row {
      width: 100%;
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;

      @include from($tablet) {
        width: 50%;
      }

      @include from($desktop) {
        width: calc(100% / 3);
      }

      // We need add special styles just for this item.-
      &.term-4545 {

        .views-field-name a {
          color: #ffffff;
          background: $primary;

          &:focus,
          &:hover {
            background: $info;
          }

        }

      }

      // We need add special styles just for this item.-
      &.term-4519 {
        display: none;
      }

      .views-field-name {
        height: 100%;
        margin: 10px;

        .field-content {
          height: 100%;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: calc(100% - 20px);
          text-align: center;
          background: #ffffff;
          transition: all .4s;
          padding: 10px;
        }
      }
    }
  }
}