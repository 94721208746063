.views-exposed-widgets {
  margin-bottom: 0;
}

section.exposed-filters {
  border-top: 1px solid $border;
  background: white !important;
}

.views-exposed-form {
  .views-exposed-widget {
    padding: 0;
    float: none;

    #block-system-main & {
      .container {
        width: auto;
      }
    }
  }
}

.filter-buttons {
  border-bottom: 1px solid $border;
  position: relative;

  > .container {
    @include from($tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    text-align: center;

    .tags {
      flex-wrap: nowrap;
      justify-content: center;
      padding: 15px 15px 0;
      margin: 0;

      @include from($tablet) {
        justify-content: flex-start;
        padding: 0 15px;
      }

      .tag {
        height: 2.25em;
        margin: 0;
      }

    }

    > .button {
      @include until($tablet) {
        font-size: .7em;
        margin: 1rem;
        display: flex;
      }
    }

    > .filter-tabs {
      @include from($tablet) {
        display: flex;
      }

      > label {
        cursor: pointer;
        @include from($tablet) {

          &:nth-child(n+2) {
            border-left: 1px solid $border;
          }
          display: flex;
          padding: 1rem;

          border-right: 1px solid $border;
        }
        @include until($tablet) {
          justify-content: space-between;
          display: flex;
          font-size: .9em;
          padding: .4rem;
          border-bottom: 1px solid $border;
        }
        font-weight: normal;

        &:not(.is-active):hover {
          background: $light;
        }

        &.is-enabled {
          color: $info;
        }

        &.is-active {
          @include from($tablet) {
            margin-bottom: -1px;
            border-bottom: 1px solid white;
          }
          font-weight: bold;

          .icon svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.filter-content {
  > * {
    border-bottom: 1px solid $border;
    display: none;
    width: 100%;
    @include until($tablet) {
      padding: 1rem !important;
    }

    &.is-active {
      display: block;
    }
  }

}

.widget-content {

  .field-label {
    display: none;
  }

  > * {
    padding: 1rem 0;

    .highlight,
    .field {
      margin-bottom: 0;
    }

    .bef-checkboxes,
    .form-radios {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      > .form-item {
        float: none;
        width: calc(100% / 3);

        @include until($tablet) {
          width: 100%;
        }
        @include tablet-only() {
          width: 40%;
        }

        .node & {
          width: 100%;
        }

        label {
          font-size: $size-7;
          font-weight: normal !important;
          padding-top: 4px;

          line-height: 1.6rem;

          &:before {
            box-sizing: border-box;
          }
        }

        &.highlight label {
          font-weight: bold !important;
        }
      }
    }
  }
}


#views-exposed-form-informes-report {
  .bef-checkboxes > .form-item {
    @include from($tablet) {
      width: 50% !important;
    }
  }
}

.views-exposed-tags {
  padding: 15px;
}
