.node.full.node-blog {
  background: $white-bis;
  padding: 2rem;
  border-radius: $radius;

  @include from($widescreen) {
    margin: 0 auto;
    max-width: $tablet;
  }
}
