.view-coit-professional-profiles {

  .bef-tree {
    padding: 15px;

    > li {
      border-bottom: 1px solid #dbdbdb;

      &:last-child {
        border-bottom: none;
      }

      > div {
        background: #f0f0f0;
        cursor: pointer;
        outline: none;
        padding: 10px 40px 10px 10px;
        position: relative;

        &.open {

          &:before {
            transform: translateY(-50%) rotate(180deg);
          }

        }

        &:before {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url("../images/arrow.png") no-repeat;
          background-size: contain;
          transform: translateY(-50%);
          transition: all 250ms;
          position: absolute;
          top: 50%;
          right: 10px;
        }

        input {
          display: none;
        }

        label {
          padding: 0 !important;
          margin: 0;

          &:before, &:after {
            content: none;
          }

        }

      }

      > ul {
        display: none;
        padding: 10px;
      }

    }

  }

  .view-empty {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding: 0 20px 45px;
  }

}
