#commerce-checkout-form-review {
  max-width: 900px;
  margin: 0 auto;
  background-color: $box-background-color;
  border-radius: $box-radius;
  box-shadow: $box-shadow;
  color: $box-color;
  display: block;
  padding: $box-padding;

  .checkout-help {
    font-size: 1.4em;
    margin: 2rem 0;
    text-align: center;
  }

  .commerce_payment {
    display: none;
  }

  .field--type-text {
    margin-bottom: 2rem;
  }

  .checkout-review {
    > tbody {
      border: none;

      > tr {
        background: none !important;
      }
    }

    tr {
      background: none !important;
    }

    .pane-title {
      display: none;
    }

    .pane-data-full {
      padding: 0;
    }
  }
}
