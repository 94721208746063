
.owl-carousel {

  .slide {
    width: 100%;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 60%;

    @include from($tablet) {
      padding-top: 25%;
    }

    .hero {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      display: flex;
      justify-items: center;

      &.slide-hero-bg-mob {
        .img-mob,
        .img-desk {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .img-mob {
          @include from($tablet) {
            display: none;
          }
        }

        .img-desk {
          display: none;
          @include from($tablet) {
            display: block;
          }
        }
      }

      .hero-body {
        justify-items: center;
        display: flex;
        align-items: flex-end;

        .container {
          width: 100%;
          margin-bottom: -2rem;
        }
      }

      .subtitle,
      .title {
        max-width: 50%;
        font-size: $size-4;
        background: rgba(white, .9);
        color: $primary;
        box-shadow: rgba(black, .4) .25rem .25rem .5rem;
        padding: .5rem 1rem;
        clear: both;
        display: inline-block;
        border-radius: $radius;
        margin-bottom: .25rem;

        @include until($tablet) {
          display: block;
        }
      }

      .subtitle {
        font-size: $size-5;
        font-weight: normal;
        background: rgba(white, .75);
      }

      @include until($desktop) {
        .container {
          width: 100%;
          margin-left: 1rem;
        }
        .subtitle,
        .title {
          font-size: 1.6rem;
        }
        .subtitle {
          font-size: 1.2rem;
          font-weight: normal;
        }
      }
      @include until($tablet) {
        .container {
          margin-bottom: .5rem !important;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
        .subtitle {
          display: none;
        }

        .title-carousel-slide,
        .subtitle-carousel-slide {
          width: 100%;
        }

        .subtitle,
        .title {
          max-width: 100%;
          text-align: center;
          font-size: 1rem;
        }
        .subtitle {
          font-size: 1rem;
          font-weight: normal;
        }
      }
    }
  }

  .owl-nav {
    display: none;
  }

}

.carousel-wrapper {
  position: relative;

  .dots {
    position: relative;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    height: 0;
    z-index: 1;
    @include until($tablet) {
      justify-content: center;
    }

    button {
      margin-top: -2rem;
      background: none;
      border-radius: 50%;
      background: white;
      border: none;
      width: 10px;
      height: 10px;
      margin-right: .7rem;
      cursor: pointer;
      padding: 0;
      box-shadow: $box-shadow;
      @include until($tablet) {
        margin-top: -1rem;
        margin-bottom: 0;
      }

      span {
        display: none;
      }

      &.active {
        background: $info;
      }
    }
  }
}


.home-banner .slide .video-slide {
  background-color: #000;
}

.home-banner.loading {
  display: none;
}

.home-banner.loading .video-background {
  opacity: 0;
}

.video-background {
  position: absolute;
  top: 0;
  margin-top: 12.5%;
  @include until($tablet) {
    margin-top: 30%;
  }
  left: 0;
  padding-top: 100%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 2s opacity ease;
  transition: 2s opacity ease;
  opacity: 1;
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 25%;
  pointer-events: none;
  @include until($tablet) {
    pointer-events: auto;
  }
}
