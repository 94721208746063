@font-face {
  font-family: "GeoGrotesque";
  src: url("../fonts/geogrotesque/Geogtq-Lg.otf");
}

@font-face {
  font-family: "GeoGrotesque";
  src: url("../fonts/geogrotesque/Geogtq-Md.otf");
  font-weight: bold;
}

@font-face {
  font-family: "GeoGrotesque";
  src: url("../fonts/geogrotesque/Geogtq-Lg.otf");
  font-style: italic;
}

$family-primary: 'GeoGrotesque', sans-serif;
$family-secondary: 'GeoGrotesque', sans-serif;

$body-size: 1.1rem;
$size-1: 3rem !default$size-2: 2.5rem !default$size-3: 2rem !default$size-4: 1.5rem !default$size-5: 1.3rem !default$size-6: 1.1rem !default$size-7: 3rem !default
