.view-coit-aeit-og-content.view-display-id-course {
  .node-event > a {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: stretch;

    .card-image {
      display: none;
    }

    .card-content {
      width: 100%;
    }
  }

}