// Bulma overwrites some Drupal navbar theming which have to be reset.

#navbar-administration {
  .navbar-menu {
    display: block;

    .label {
      display: none;
    }
  }
}