
.field-label-above {
  margin-top: 1rem;

  .field-label {
    display: block;
    text-align: left;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
    border-bottom: 1px solid $light;
    font-size: .8rem;
    font-weight: 700;
  }
}

.field-label-inline {
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }

  .field-label {
    min-width: calc(100% / 6);
    margin-right: 1.5rem;
    padding-top: .2rem;
    font-size: .8rem;
    font-weight: 700;
  }

  .field-items {
    display: flex;
    flex-basis: 0;
    flex-grow: 3;
    flex-shrink: 1;
    flex-direction: column;
  }
}