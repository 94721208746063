.hero.eu-cookie-compliance {
  font-size: .9em;

  .hero-body {
    padding: 2rem 1rem;
    text-align: left;
  }

  .content {
    max-width: 1000px;
    margin-right: 1rem;
  }

  h4 {
    color: white;
  }

  .agree-button {
    border: 1px solid $white !important;

    &:hover {
      background-color: white !important;
      border-color: $grey-dark !important;
      color: $grey-dark !important;
    }
  }

  @include until($desktop) {
    .level {
      display: block;
    }
    .content {
      margin-right: 0;
    }
    #popup-buttons {
      width: 100%;
      max-width: none !important;

      .agree-button {
        width: 100%;
      }
    }
  }

}